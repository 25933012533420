const REQUEST_TYPES = {
	YEAR: 'year',
	MAKE: 'make',
	MODEL: 'model',
	TRIM: 'trim',
	ENGINE: 'engine',
};

const nextUrl = 'ajax/vehicle-picker/next';
const setVehicleUrl = '/ajax/vehicle-picker/select';

const getData = (step, data) => {
	return {
		step,
		picker_type: 'normal',
		selected: data,
	};
};

const vpRequest = ({ type, method = 'GET', data = {}, error, success }) => {
	const validRequestTypes = Object.values(REQUEST_TYPES);
	if (!validRequestTypes.includes(type)) {
		return new Error(`Invalid request type. One of ${validRequestTypes.join(', ')} expected.`);
	}

	return $ajax({
		method,
		dataType: 'json',
		data: getData(type, data),
		url: nextUrl,
		error,
		success,
	});
};

const fetchYears = ({make, model}, success, error) => {
	return vpRequest({
		type: REQUEST_TYPES.YEAR,
		data: {
			make,
			model,
		},
		success,
		error,
	});
};

const fetchMakes = ({year}, success, error) => {
	return vpRequest({
		type: REQUEST_TYPES.MAKE,
		data: {
			year,
		},
		success,
		error,
	});
};

const fetchModels = ({year, make}, success, error) => {
	return vpRequest({
		type: REQUEST_TYPES.MODEL,
		data: {
			year,
			make,
		},
		success,
		error,
	});
};

const fetchTrims = ({year, make, model}, success, error) => {
	return vpRequest({
		type: REQUEST_TYPES.TRIM,
		data: {
			year,
			make,
			model,
		},
		success,
		error,
	});
};

const fetchEngines = ({year, make, model, trim}, success, error) => {
	return vpRequest({
		type: REQUEST_TYPES.ENGINE,
		data: {
			year,
			make,
			model,
			trim,
		},
		success,
		error,
	});
};

const setVehicle = (data, error, success) => {
	return $ajax({
		method: 'POST',
		dataType: 'json',
		url: setVehicleUrl,
		data,
		error,
		success,
	});
};

const getDropdownContentFromData = (data={}, selectedValue) => {
	return Object.values(data).map((item={}) => ({
		id: item.url || item.id,
		label: item.ui || item.label,
		selected: item.url === selectedValue || item.label === selectedValue,
	}));
};

const getNextStep = (data={}, success) => {
	const {make, model, year, trim} = data;
	if (make && model && year && trim) {
		return fetchEngines(data, success);
	} else if (make && model && year) {
		return fetchTrims(data, success);
	} else if (make && year) {
		return fetchModels(data, success);
	} else if (year) {
		return fetchMakes(data, success);
	} else {
		return fetchYears(data, success);
	}
};

const handleRedirect = ({make, model, year, engine, trim, accessories=false, primary_make=null}, redirectUrl) => {
	let url = `/v-${year}-${make}-${model}`;
	if (trim && engine) {
		url += `--${trim}--${engine}`;
	}
	if (redirectUrl === 'categories') {
		redirectUrl = url + '/categories';
	}
	if (accessories) {
		url += '#accessories';
	}
	if(primary_make === '0'){
		url += '?brandType=aftermarket';
	}

	rp_app.location.redirect(redirectUrl || url);
};

module.exports = {
	REQUEST_TYPES,
	fetchYears,
	fetchMakes,
	fetchModels,
	fetchTrims,
	fetchEngines,
	getNextStep,
	setVehicle,
	handleRedirect,
	getDropdownContentFromData,
};
